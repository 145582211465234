import React from "react";
import './legalMention.css'



const LegalMention: React.FC = () => {
    return (
        <div className="legalMention">
            <header className="legalMention-header">
                <p>Mentions légales / ©Aymeric sabatier</p>
            </header>
        </div>
    );
}


export default LegalMention;